<template>
  <div>
    <div class="d-block d-md-none mb-2">
      <b-button
        v-show="$can('tiendaCrear', 'tiendaCrear')"
        v-if="userData.rolId=='1'"
        variant="primary"
        block
        @click="modalCategoria(0)"
      >
        <feather-icon
          icon="FileIcon"
          class="mr-50"
        />
        <span>Crear categoria</span>
      </b-button>
    </div>
    <div class="d-none d-md-flex align-items-center justify-content-end mb-2">
      <b-button
        v-if="userData.rolId=='1'"
        v-show="$can('tiendaCrear', 'tiendaCrear')"
        variant="primary"
        @click="modalCategoria(0)"
      >
        <feather-icon
          icon="FileIcon"
          class="mr-50"
        />
        <span>Crear categoria</span>
      </b-button>
    </div>
    <b-row>
      <b-col cols="12">
        <b-card title="Categorias creadas">
          <b-row class="match-height">
            <b-col
              v-for="item in itemsCategoria"
              :key="item.id"
              cols="2"
              :class="(idCategoria === item.id) ? 'bg-light-primary rounded': 'none'"
            >
              <div class="d-flex flex-column mb-1">
                <div class="p-1">
                  <b-img
                    v-if="item.activo == '1'"
                    :id="`Seleccionar-${item.id}`"
                    class="img"
                    center
                    rounded="circle"
                    :src="item.imagen"
                    :alt="item.name"
                    @click="onCategoriaSelect(item)"
                  />
                  <b-img
                    v-else
                    :id="`Seleccionar-${item.id}`"
                    class="img-inactivo"
                    center
                    rounded="circle"
                    :src="item.imagen"
                    :alt="item.name"
                    @click="onCategoriaSelect(item)"
                  />
                </div>
                <div class="mt-2 text-center">
                  <b-button
                    v-if="userData.rolId=='1'"
                    v-show="$can('tiendaActualizar', 'tiendaActualizar')"
                    :id="`Editar-${item.id}`"
                    variant="gradient-success"
                    class="btn-icon mr-50"
                    size="sm"
                    @click="modalCategoria(item.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  {{ item.name }}
                  <b-tooltip
                    :target="`Seleccionar-${item.id}`"
                    title="Seleccionar"
                  />
                  <b-tooltip
                    :target="`Editar-${item.id}`"
                    title="Editar"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
          <!---
          <b-table
            responsive
            striped
            hover
            show-empty
            bordered
            sticky-header="250px"
            :small="true"
            :busy="cargandoCategoria"
            :items="itemsCategoria"
            :fields="fieldsCategoria"
            select-mode="single"
            selectable
            @row-selected="onCategoriaSelect"
          >
            <template #empty>
              <h4 class="text-center my-2">
                No tiene información para mostrar
              </h4>
            </template>
            <template #head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template #cell(seleccion)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
            <template v-slot:cell(activo)="row">
              <span v-if="row.item.activo === '0'">Inactivo</span>
              <span v-else>Activo</span>
            </template>
            <template #cell(acciones)="row">
              <b-button
                v-show="$can('tiendaActualizar', 'tiendaActualizar')"
                variant="gradient-success"
                class="btn-icon mr-50"
                size="sm"
                @click="modalCategoria(row.item.id)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-show="$can('tiendaEliminar', 'tiendaEliminar')"
                variant="gradient-danger"
                class="btn-icon"
                size="sm"
                @click="eliminarCategoria(row.item.id)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </template>
          </b-table>
          -->
        </b-card>
      </b-col>
    </b-row>
    <div
      v-if="idCategoria!=''"
      class="d-block d-md-none mb-2"
    >
      <b-button
        variant="primary"
        block
        @click="modalProducto(0)"
      >
        <feather-icon
          icon="FileIcon"
          class="mr-50"
        />
        <span>Crear item</span>
      </b-button>
    </div>
    <div
      v-if="idCategoria!=''"
      class="d-none d-md-flex align-items-center justify-content-end mb-2"
    >
      <b-button
        variant="primary"
        @click="modalProducto(0)"
      >
        <feather-icon
          icon="FileIcon"
          class="mr-50"
        />
        <span>Crear item</span>
      </b-button>
    </div>
    <b-row v-if="idCategoria!=''">
      <b-col cols="12">
        <b-card>
          <template #header>
            <div class="d-flex flex-row align-items-center">
              <div class="">
                <h4 class="mb-0">
                  Items creados ({{ tituloItems }})
                </h4>
              </div>
            </div>
          </template>
          <b-table
            responsive
            striped
            hover
            show-empty
            bordered
            sticky-header="250px"
            :small="true"
            :busy="cargandoProducto"
            :items="itemsProducto"
            :fields="fieldsProducto"
          >
            <template #empty>
              <h4 class="text-center my-2">
                No tiene información para mostrar
              </h4>
            </template>
            <template #head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(imagen)="row">
              <b-img
                v-if="row.item.activo == '1'"
                :src="row.item.imagen"
                class="icono"
                rounded
                width="70"
                height="50"
              />
              <b-img
                v-else
                :src="row.item.imagen"
                class="icono-inactivo"
                rounded
                width="70"
                height="50"
              />
            </template>
            <template #cell(acciones)="row">
              <b-button
                variant="gradient-success"
                class="btn-icon mr-50"
                size="sm"
                @click="modalProducto(row.item.id)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                variant="gradient-danger"
                class="btn-icon"
                size="sm"
                @click="eliminarProducto(row.item.id)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="popupProducto"
      hide-footer
      scrollable
      no-close-on-backdrop
      :size="(form.id > 0) ? 'xl': 'lg'"
      :title="tituloProducto"
      @hidde="limpiarProductoTotal"
    >
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between">
            <p class="text-right">
              <small class="text-muted">Los campos marcados con * son obligatorios</small>
            </p>
            <div class="d-flex align-items-center justify-content-end mb-2">
              <b-button
                variant="primary"
                class="ml-1"
                @click="registrarProducto"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50 text-white"
                />
                <span class="text-white">Grabar</span>
              </b-button>
              <b-button
                variant="danger"
                class="ml-1"
                @click="limpiarProducto"
              >
                <feather-icon
                  icon="FileIcon"
                  class="mr-50"
                />
                Limpiar
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="form.id > 0"
          md="4"
        >
          <b-img
            :src="form.urlImagen"
            rounded
            fluid-grow
          />
        </b-col>
        <b-col :md="(form.id > 0) ? '8': '12'">
          <validation-observer ref="filterProducto">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="categoria"
                  label="Categoria *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="categoria"
                    rules="required"
                  >
                    <b-form-select
                      id="categoria"
                      ref="categoria"
                      v-model="form.categoria"
                      :disabled="(form.id > 0) ? true : true"
                      :options="optionsCategoria"
                      :state="errors.length > 0 ? false : null"
                      @input="obtenerTipo"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--
              <b-col md="6">
                <b-form-group
                  label-for="tipo"
                  label="Tipo"
                >
                  <b-form-select
                    id="tipo"
                    ref="tipo"
                    v-model="form.tipo"
                    :options="optionsTipo"
                  />
                </b-form-group>
              </b-col>
              -->
            </b-row>
            <b-row>
              <!---
              <b-col md="6">
                <b-form-group
                  label-for="tipo"
                  label="Tipo de compra *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="tipo de compra"
                    rules="required"
                  >
                    <b-form-select
                      id="tipo"
                      ref="tipo"
                      v-model="form.clase"
                      :options="optionsFrecuencia"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              -->
              <b-col md="6">
                <b-form-group
                  label-for="empresa"
                  label="Empresa"
                >
                  <b-form-select
                    id="empresa"
                    ref="empresa"
                    v-model="form.empresa"
                    :options="optionsEmpresa"
                    @input="obtenerArea"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="area"
                  label="Area"
                >
                  <b-form-select
                    id="area"
                    ref="area"
                    v-model="form.area"
                    :options="optionsArea"
                    @input="obtenerCampana"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-for="campana"
                  label="Campaña"
                >
                  <b-form-select
                    id="campana"
                    ref="campana"
                    v-model="form.campana"
                    :options="optionsCampana"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="nombre"
                  label="Nombre *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="nombre"
                    rules="required"
                  >
                    <b-form-input
                      id="nombre"
                      ref="nombre"
                      v-model="form.nombre"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="descripcion"
                  label="Descripcion *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="descripcion"
                    rules="required"
                  >
                    <b-form-input
                      id="descripcion"
                      ref="descripcion"
                      v-model="form.descripcion"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar descripcion"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="form.id == 0">
              <b-col>
                <b-form-group
                  label-for="imagen"
                  label="Imagen *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="imagen"
                    rules="required"
                  >
                    <b-form-file
                      id="imagen"
                      ref="imagen"
                      v-model="form.imagen"
                      placeholder="Seleccionar imagen..."
                      drop-placeholder="Eliminar imagen..."
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="propiedad"
                  label="Propiedad"
                >
                  <b-form-textarea
                    id="propiedad"
                    ref="propiedad"
                    v-model="form.propiedad"
                    placeholder="Ingresar propiedad"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="instrucciones"
                  label="Instrucciones para el canje"
                >
                  <b-form-textarea
                    id="instrucciones"
                    ref="instrucciones"
                    v-model="form.instrucciones"
                    placeholder="Ingresar instrucciones"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="frecuencia"
                  label="Tipo usuario *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="tipo usuario"
                    rules="required"
                  >
                    <b-form-select
                      id="frecuencia"
                      ref="frecuencia"
                      v-model="form.tipoUsuario"
                      :options="optionsTipoUsuario"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-for="titulo-condicion"
                  label="Titulo condición"
                >
                  <b-form-input
                    id="titulo-condicion"
                    ref="titulo-condicion"
                    v-model="form.tituloCondiciones"
                    placeholder="Ingresar titulo condición"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="condiciones"
                  label="Condiciones"
                >
                  <b-form-textarea
                    id="condiciones"
                    ref="condiciones"
                    v-model="form.condiciones"
                    placeholder="Ingresar condiciones"
                    rows="2"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="precio"
                  label="Precio"
                >
                  <b-form-input
                    id="precio"
                    ref="precio"
                    v-model="form.precio"
                    placeholder="Ingresar precio"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="stock"
                  label="Stock"
                >
                  <b-form-input
                    id="stock"
                    ref="stock"
                    v-model="form.stock"
                    placeholder="Ingresar stock"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="form.clase != '1'">
                <b-form-group
                  label-for="maximo"
                  label="Maximo"
                >
                  <b-form-input
                    id="maximo"
                    ref="maximo"
                    v-model="form.maximo"
                    placeholder="Ingresar maximo"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="estado"
                  label="Estado *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="estado"
                    rules="required"
                  >
                    <b-form-select
                      id="estado"
                      ref="estado"
                      v-model="form.estado"
                      :options="optionsEstado"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="6"
                md="4"
              >
                <b-form-group
                  label-for="estado"
                  label="Fecha inicio vigencia"
                >
                  <b-form-datepicker
                    v-model="form.fechaIni"
                    label-no-date-selected=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="6"
                md="2"
              >
                <b-form-group
                  label-for="estado"
                  label="Hora inicio vigencia"
                >
                  <b-form-timepicker
                    v-model="form.horaIni"
                    locale="es"
                    label-no-time-selected=""
                    show-seconds
                    label-close-button="Cerrar"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="6"
                md="4"
              >
                <b-form-group
                  label-for="estado"
                  label="Fecha fin vigencia"
                >
                  <b-form-datepicker
                    v-model="form.fechaFin"
                    label-no-date-selected=""
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="6"
                md="2"
              >
                <b-form-group
                  label-for="estado"
                  label="Hora fin vigencia"
                >
                  <b-form-timepicker
                    v-model="form.horaFin"
                    locale="es"
                    label-no-time-selected=""
                    show-seconds
                    label-close-button="Cerrar"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-model="popupCategoria"
      hide-footer
      scrollable
      no-close-on-backdrop
      :size="(form.id > 0) ? 'xl': 'lg'"
      :title="tituloCategoria"
      @hidde="limpiarCategoriaTotal"
    >
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between">
            <p class="text-right">
              <small class="text-muted">Los campos marcados con * son obligatorios</small>
            </p>
            <div class="d-flex align-items-center justify-content-end mb-2">
              <b-button
                variant="primary"
                class="ml-1"
                @click="registrarCategoria"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50 text-white"
                />
                <span class="text-white">Grabar</span>
              </b-button>
              <b-button
                variant="danger"
                class="ml-1"
                @click="limpiarCategoria"
              >
                <feather-icon
                  icon="FileIcon"
                  class="mr-50"
                />
                Limpiar
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="formCategoria.id > 0"
          md="4"
        >
          <b-img
            :src="formCategoria.urlImagen"
            rounded
            fluid-grow
          />
        </b-col>
        <b-col :md="(formCategoria.id > 0) ? '8': '12'">
          <validation-observer ref="filterCategoria">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="empresa"
                  label="Empresa"
                >
                  <b-form-select
                    id="empresa"
                    ref="empresa"
                    v-model="formCategoria.empresa"
                    :options="optionsEmpresa"
                    @input="obtenerAreaCategoria"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-for="area"
                  label="Area"
                >
                  <b-form-select
                    id="area"
                    ref="area"
                    v-model="formCategoria.area"
                    :options="optionsArea"
                    @input="obtenerCampanaCategoria"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="campana"
                  label="Campaña"
                >
                  <b-form-select
                    id="campana"
                    ref="campana"
                    v-model="formCategoria.campana"
                    :options="optionsCampana"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-for="frecuencia"
                  label="Tipo usuario *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="tipo usuario"
                    rules="required"
                  >
                    <b-form-select
                      id="frecuencia"
                      ref="frecuencia"
                      v-model="formCategoria.tipoUsuario"
                      :options="optionsTipoUsuario"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="frecuencia"
                  label="Frecuencia de compra *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="frecuencia de compra"
                    rules="required"
                  >
                    <b-form-select
                      id="frecuencia"
                      ref="frecuencia"
                      v-model="formCategoria.clase"
                      :options="optionsFrecuencia"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="nombre"
                  label="Nombre *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="nombre"
                    rules="required"
                  >
                    <b-form-input
                      id="nombre"
                      ref="nombre"
                      v-model="formCategoria.nombre"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="descripcion"
                  label="Descripcion *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="descripcion"
                    rules="required"
                  >
                    <b-form-input
                      id="descripcion"
                      ref="descripcion"
                      v-model="formCategoria.descripcion"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Ingresar descripcion"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="formCategoria.id == 0">
              <b-col>
                <b-form-group
                  label-for="imagenCategoria"
                  label="Imagen *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="imagen"
                    rules="required"
                  >
                    <b-form-file
                      id="imagenCategoria"
                      ref="imagenCategoria"
                      v-model="formCategoria.imagen"
                      placeholder="Seleccionar imagen..."
                      drop-placeholder="Eliminar imagen..."
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="estado"
                  label="Estado *"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="estado"
                    rules="required"
                  >
                    <b-form-select
                      id="estado"
                      ref="estado"
                      v-model="formCategoria.estado"
                      :options="optionsEstado"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BButton, BFormSelect, BModal, BFormGroup, BImg, BFormInput, BFormFile, BFormTextarea, BTooltip, BFormDatepicker, BFormTimepicker,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import usuarioService from '@/service/usuarioService'
// eslint-disable-next-line import/no-cycle
import productoService from '@/service/productoService'
// eslint-disable-next-line import/no-cycle
import categoriaService from '@/service/categoriaService'
// eslint-disable-next-line import/no-cycle
import empresaService from '@/service/empresaService'
// eslint-disable-next-line import/no-cycle
import areaService from '@/service/areaService'
// eslint-disable-next-line import/no-cycle
import campanaService from '@/service/campanaService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BModal,
    BFormSelect,
    BFormGroup,
    BImg,
    BFormInput,
    BFormFile,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BTooltip,
    BFormDatepicker,
    BFormTimepicker,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      token: localStorage.getItem('token'),
      required,
      cargandoCategoria: false,
      filterCategoria: null,
      filterOnCategoria: [],
      fieldsCategoria: [
        {
          key: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: 'Selección',
          key: 'seleccion',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Nombre',
          key: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Descripción',
          key: 'description',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Estado',
          key: 'activo',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Acciones',
          key: 'acciones',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsCategoria: [],
      popupCategoria: false,
      tituloCategoria: '',
      idCategoria: '',
      tituloItems: '',
      imagenItems: '',
      cargandoProducto: false,
      filterProducto: null,
      filterOnProducto: [],
      fieldsProducto: [
        {
          key: 'id',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          label: 'Icono',
          key: 'imagen',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Empresa',
          key: 'compania',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Area',
          key: 'area',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Campaña',
          key: 'campana',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Tipo',
          key: 'tipo',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nombre',
          key: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Descripcion',
          key: 'description',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Acciones',
          key: 'acciones',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      itemsProducto: [],
      popupProducto: false,
      tituloProducto: '',
      optionsFrecuencia: [],
      optionsTipoUsuario: [{ value: null, text: 'Seleccionar tipo' }],
      optionsCategoria: [{ value: null, text: 'Seleccionar categoria' }],
      optionsEmpresa: [{ value: null, text: 'Seleccionar empresa' }],
      optionsArea: [{ value: null, text: 'Seleccionar area' }],
      optionsCampana: [{ value: null, text: 'Seleccionar campaña' }],
      dataTipo: [],
      optionsTipo: [{ value: null, text: 'Seleccionar tipo' }],
      optionsEstado: [
        { value: null, text: 'Seleccionar estado' },
        { value: '1', text: 'Activo' },
        { value: '0', text: 'Inactivo' },
      ],
      form: {
        id: 0,
        usuario: '',
        categoria: null,
        empresa: null,
        area: null,
        campana: null,
        tipo: null,
        clase: null,
        nombre: '',
        descripcion: '',
        precio: '',
        stock: '',
        maximo: '',
        imagen: null,
        urlImagen: '',
        propiedad: '',
        instrucciones: '',
        estado: '1',
        tipoUsuario: null,
        tituloCondiciones: '',
        condiciones: '',
        fechaIni: '',
        horaIni: '',
        fechaFin: '',
        horaFin: '',
      },
      formCategoria: {
        id: 0,
        empresa: null,
        area: null,
        campana: null,
        tipoUsuario: null,
        nombre: '',
        descripcion: '',
        imagen: null,
        urlImagen: '',
        estado: '1',
        clase: null,
      },
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      await this.obtenerTipoUsuario()
      await this.obtenerCategoria()
      await this.obtenerProductoTipo()
      await this.obtenerEmpresa()
      await this.obtenerFrecuencia()
      await this.obtenerCategorias()
      this.$store.commit('app/UPDATE_ISLOADING', false)
    })
  },
  methods: {
    async onCategoriaSelect(items) {
      this.idCategoria = ''
      this.tituloItems = ''
      this.imagenItems = ''
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo informacion.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      this.idCategoria = items.id
      this.tituloItems = items.name
      this.imagenItems = items.imagen

      await this.obtenerProducto()

      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async modalCategoria(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      if (id === 0) {
        this.tituloCategoria = 'Agregar categoria'
        this.formCategoria = {
          id: 0,
          empresa: null,
          area: null,
          campana: null,
          tipoUsuario: null,
          nombre: '',
          descripcion: '',
          imagen: null,
          urlImagen: '',
          estado: '1',
          clase: null,
        }
      } else {
        this.tituloProducto = 'Modificar categoria'
        const data = this.itemsCategoria.find(element => element.id === id)
        this.formCategoria.empresa = (data.company === '') ? null : data.company
        await this.obtenerAreaCategoria()
        this.formCategoria.area = (data.area === '') ? null : data.area
        await this.obtenerCampanaCategoria()
        this.formCategoria.campana = (data.campaign === '') ? null : data.campaign
        this.formCategoria.id = data.id
        this.formCategoria.categoria = data.id_categoria
        this.formCategoria.nombre = data.name
        this.formCategoria.descripcion = data.description
        this.formCategoria.urlImagen = data.imagen
        this.formCategoria.imagen = null
        this.formCategoria.estado = data.activo
        this.formCategoria.tipoUsuario = data.tipoUsuario
        this.formCategoria.clase = data.clase
      }
      this.popupCategoria = true
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async obtenerTipoUsuario() {
      this.optionsTipoUsuario = [{ value: null, text: 'Seleccionar tipo' }]
      const response = await usuarioService.postTipoUsuarioData(
        this.token,
      )
      if (response.data.data.length > 0) {
        response.data.data.forEach(element => this.optionsTipoUsuario.push({
          value: element.tipoUsuario,
          text: element.tipoUsuario,
        }))
      }
    },
    async obtenerFrecuencia() {
      this.optionsFrecuencia = []
      const response = await productoService.postClaseProductoData(
        this.token,
      )
      if (response.data.data.length > 0) {
        response.data.data.forEach(element => this.optionsFrecuencia.push({
          value: element.value,
          text: element.text,
        }))
      }
    },
    async obtenerCategoria() {
      this.optionsCategoria = [{ value: null, text: 'Seleccionar categoria' }]
      const response = await categoriaService.postCategoriaData(
        this.token,
      )
      if (response.data.data.length > 0) {
        response.data.data.forEach(element => this.optionsCategoria.push({
          value: element.id,
          text: element.name,
        }))
      }
    },
    async obtenerProductoTipo() {
      this.optionsTipo = [{ value: null, text: 'Seleccionar tipo' }]
      const response = await productoService.postProductoTipoData(
        this.token,
      )
      if (response.data.data.length > 0) {
        response.data.data.forEach(element => this.dataTipo.push({
          value: element.key,
          text: element.value,
          carpeta: element.carpeta,
          categoria: element.categoria,
        }))
      }
    },
    async obtenerTipo() {
      this.optionsTipo = [{ value: null, text: 'Seleccionar tipo' }]
      this.dataTipo.forEach(e => {
        if (e.categoria === this.form.categoria) {
          this.optionsTipo.push({
            value: e.value,
            text: e.text,
            carpeta: e.carpeta,
            categoria: e.categoria,
          })
        }
      })
    },
    async obtenerEmpresa() {
      this.optionsEmpresa = [{ value: null, text: 'Seleccionar empresa' }]
      const responseEmpresa = await empresaService.postEmpresaUsuarioData(this.token, this.userData.id)
      if (responseEmpresa.data.data.length > 0) {
        responseEmpresa.data.data.forEach(element => this.optionsEmpresa.push({
          value: element.id,
          text: element.name,
        }))
      }
    },
    async obtenerAreaCategoria() {
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo información.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      this.optionsArea = [{ value: null, text: 'Seleccionar area' }]
      this.optionsCampana = [{ value: null, text: 'Seleccionar campaña' }]
      this.formCategoria.area = null
      this.formCategoria.campana = null
      if (this.formCategoria.empresa != null) {
        const responseArea = await areaService.postAreaData(
          this.token,
          this.formCategoria.empresa,
        )
        if (responseArea.data.data.length > 0) {
          responseArea.data.data.forEach(element => this.optionsArea.push({
            value: element.id,
            text: element.name,
          }))
        }
      }
      setTimeout(() => {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }, 1000)
    },
    async obtenerArea() {
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo información.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      this.optionsArea = [{ value: null, text: 'Seleccionar area' }]
      this.optionsCampana = [{ value: null, text: 'Seleccionar campaña' }]
      this.form.area = null
      this.form.campana = null
      if (this.form.empresa != null) {
        const responseArea = await areaService.postAreaData(
          this.token,
          this.form.empresa,
        )
        if (responseArea.data.data.length > 0) {
          responseArea.data.data.forEach(element => this.optionsArea.push({
            value: element.id,
            text: element.name,
          }))
        }
      }
      setTimeout(() => {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }, 1000)
    },
    async obtenerCampanaCategoria() {
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo información.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      this.optionsCampana = [{ value: null, text: 'Seleccionar campaña' }]
      this.formCategoria.campana = null
      if (this.formCategoria.area != null) {
        const responseCampana = await campanaService.postCampanaData(
          this.token,
          this.formCategoria.area,
        )
        if (responseCampana.data.data.length > 0) {
          responseCampana.data.data.forEach(element => this.optionsCampana.push({
            value: element.id,
            text: element.name,
          }))
        }
      }
      setTimeout(() => {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }, 1000)
    },
    async obtenerCampana() {
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo información.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      this.optionsCampana = [{ value: null, text: 'Seleccionar campaña' }]
      this.form.campana = null
      if (this.form.area != null) {
        const responseCampana = await campanaService.postCampanaData(
          this.token,
          this.form.area,
        )
        if (responseCampana.data.data.length > 0) {
          responseCampana.data.data.forEach(element => this.optionsCampana.push({
            value: element.id,
            text: element.name,
          }))
        }
      }
      setTimeout(() => {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }, 1000)
    },
    async obtenerCategorias() {
      this.itemsCategoria = []
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo información.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const response = await categoriaService.postCategoriasData(
        this.token, this.userData.id,
      )
      if (response.data.data.length > 0) {
        this.itemsCategoria = response.data.data
      }
      setTimeout(() => {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }, 1000)
    },
    async obtenerProducto() {
      this.itemsProducto = []
      this.$store.commit('app/UPDATE_MESSAGE', 'Obteniendo información.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      const response = await productoService.postProductoData(
        this.token, this.idCategoria,
      )
      if (response.data.data.length > 0) {
        this.itemsProducto = response.data.data
      }
      setTimeout(() => {
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }, 1000)
    },
    async modalProducto(id) {
      this.$store.commit('app/UPDATE_ISLOADING', true)
      if (id === 0) {
        this.tituloProducto = 'Agregar item'
        this.form = {
          id: 0,
          categoria: this.idCategoria,
          empresa: null,
          campana: null,
          area: null,
          tipo: null,
          clase: null,
          nombre: '',
          descripcion: '',
          precio: '',
          stock: '',
          maximo: '',
          imagen: null,
          propiedad: '',
          instrucciones: '',
          estado: '1',
          tipoUsuario: null,
          tituloCondiciones: '',
          condiciones: '',
        }
        await this.obtenerTipo()
      } else {
        this.tituloProducto = 'Modificar item'
        const data = this.itemsProducto.find(element => element.id === id)
        this.form.empresa = (data.id_compania === '') ? null : data.id_compania
        await this.obtenerArea()
        this.form.area = (data.id_area === '') ? null : data.id_area
        await this.obtenerCampana()
        this.form.campana = (data.campana === '') ? null : data.id_campana
        this.form.id = data.id
        this.form.categoria = data.id_categoria
        await this.obtenerTipo()
        this.form.tipo = (data.tipo) ? data.tipo : ''
        this.form.clase = (data.clase) ? data.clase : ''
        this.form.nombre = data.name
        this.form.descripcion = data.description
        this.form.precio = data.precio
        this.form.stock = data.stock
        this.form.maximo = data.maximo
        this.form.urlImagen = data.imagen
        this.form.imagen = null
        this.form.propiedad = data.propiedades
        this.form.instrucciones = (data.instruccionesCanje) ? data.instruccionesCanje : ''
        this.form.estado = data.activo
        this.form.tipoUsuario = data.tipoUsuario
        this.form.tituloCondiciones = data.tituloCondiciones
        this.form.condiciones = data.condiciones
        this.form.fechaIni = data.fechaIni
        this.form.horaIni = data.horaIni
        this.form.fechaFin = data.fechaFin
        this.form.horaFin = data.horaFin
      }
      this.popupProducto = true
      this.$store.commit('app/UPDATE_ISLOADING', false)
    },
    async registrarProducto() {
      const success = await this.$refs.filterProducto.validate()
      if (success) {
        this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
        this.$store.commit('app/UPDATE_ISLOADING', true)
        try {
          if (this.form.id === 0) {
            const dataTipo = this.optionsTipo.find(element => element.value === this.form.tipo)
            let carpeta = ''
            if (dataTipo) {
              carpeta = dataTipo.carpeta
              this.form.tipo = dataTipo.value
            }

            const data = new FormData()
            data.append('imagen', this.$refs.imagen.files[0])
            data.append('categoria', this.idCategoria)
            data.append('empresa', this.form.empresa)
            data.append('area', this.form.area)
            data.append('campana', this.form.campana)
            // data.append('clase', this.form.clase)
            // data.append('tipo', this.form.tipo)
            data.append('carpeta', carpeta)
            data.append('nombre', this.form.nombre)
            data.append('descripcion', this.form.descripcion)
            data.append('propiedad', this.form.propiedad)
            data.append('instruccionesCanje', this.form.instrucciones)
            data.append('stock', this.form.stock)
            data.append('maximo', this.form.maximo)
            data.append('precio', this.form.precio)
            data.append('estado', this.form.estado)
            data.append('tipoUsuario', this.form.tipoUsuario)
            data.append('tituloCondiciones', this.form.tituloCondiciones)
            data.append('condiciones', this.form.condiciones)
            data.append('fechaIni', this.form.fechaIni)
            data.append('horaIni', this.form.horaIni)
            data.append('fechaFin', this.form.fechaFin)
            data.append('horaFin', this.form.horaFin)
            data.append('instruccionesCanje', this.form.instrucciones)
            await productoService.postProductoInsert(this.token, data)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'Tienda registrada con éxito.',
                },
              },
              {
                position: 'bottom-center',
                closeOnClick: true,
              },
            )
          } else {
            await productoService.postProductoUpdate(this.token, this.form)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'Tienda actualizada con éxito.',
                },
              },
              {
                position: 'bottom-center',
                closeOnClick: true,
              },
            )
          }

          this.popupProducto = false
          setTimeout(() => {
            this.obtenerProducto()
          }, 1000)
        } catch (error) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Ocurrio un error, por favor intentar nuevamente.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      }
    },
    async eliminarProducto(id) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        await productoService.postProductoDelete(this.token, id)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Proceso eliminado con éxito.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
        setTimeout(() => {
          this.obtenerProducto()
        }, 1000)
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    limpiarProducto() {
      this.form.categoria = null
      this.form.empresa = null
      this.form.campana = null
      this.form.area = null
      this.form.tipo = null
      this.form.clase = null
      this.form.nombre = ''
      this.form.descripcion = ''
      this.form.precio = ''
      this.form.stock = ''
      this.form.maximo = ''
      this.form.propiedad = ''
      this.form.instrucciones = ''
      this.form.estado = '1'
      this.form.tipoUsuario = null
      this.form.tituloCondiciones = ''
      this.form.condiciones = ''
      this.form.fechaIni = ''
      this.form.horaIni = ''
      this.form.fechaFin = ''
      this.form.horaFin = ''
    },
    limpiarProductoTotal() {
      this.form.id = 0
      this.form.imagen = null
      this.form.urlImagen = ''
      this.limpiarProducto()
    },
    async registrarCategoria() {
      const success = await this.$refs.filterCategoria.validate()
      if (success) {
        this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
        this.$store.commit('app/UPDATE_ISLOADING', true)
        try {
          if (this.formCategoria.id === 0) {
            const data = new FormData()
            data.append('company', this.formCategoria.empresa)
            data.append('area', this.formCategoria.area)
            data.append('campaign', this.formCategoria.campana)
            data.append('name', this.formCategoria.nombre)
            data.append('description', this.formCategoria.descripcion)
            data.append('imagen', this.$refs.imagenCategoria.files[0])
            data.append('estado', this.formCategoria.estado)
            data.append('tipoUsuario', this.formCategoria.tipoUsuario)
            data.append('clase', this.formCategoria.clase)
            await categoriaService.postCategoriaInsert(this.token, data)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'Categoria registrada con éxito.',
                },
              },
              {
                position: 'bottom-center',
                closeOnClick: true,
              },
            )
          } else {
            await categoriaService.postCategoriaUpdate(this.token, this.formCategoria)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'Categoria actualizada con éxito.',
                },
              },
              {
                position: 'bottom-center',
                closeOnClick: true,
              },
            )
          }

          this.popupCategoria = false
          setTimeout(() => {
            this.obtenerCategorias()
          }, 1000)
        } catch (error) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Ocurrio un error, por favor intentar nuevamente.',
              },
            },
            {
              position: 'bottom-center',
              closeOnClick: true,
            },
          )
          this.$store.commit('app/UPDATE_ISLOADING', false)
        }
      }
    },
    async eliminarCategoria(id) {
      this.$store.commit('app/UPDATE_MESSAGE', 'Generando proceso.')
      this.$store.commit('app/UPDATE_ISLOADING', true)
      try {
        await categoriaService.postCategoriaDelete(this.token, id)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Categoria eliminada con éxito.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
        setTimeout(() => {
          this.obtenerProducto()
        }, 1000)
      } catch (error) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Ocurrio un error, por favor intentar nuevamente.',
            },
          },
          {
            position: 'bottom-center',
            closeOnClick: true,
          },
        )
        this.$store.commit('app/UPDATE_ISLOADING', false)
      }
    },
    limpiarCategoria() {
      this.formCategoria.empresa = null
      this.formCategoria.campana = null
      this.formCategoria.area = null
      this.formCategoria.nombre = ''
      this.formCategoria.descripcion = ''
      this.formCategoria.estado = '1'
      this.formCategoria.tipoUsuario = null
    },
    limpiarCategoriaTotal() {
      this.formCategoria.id = 0
      this.formCategoria.imagen = null
      this.formCategoria.urlImagen = ''
      this.limpiarProducto()
    },
  },
}
</script>

<style>
.img {
  max-width: 100px;
  max-height: 100px;
  cursor: pointer;
}
.img-inactivo {
  max-width: 100px;
  max-height: 100px;
  cursor: pointer;
  opacity: 0.2;
}
.icono {
  max-width: 50px;
  max-height: 70px;
}
.icono-inactivo {
  max-width: 50px;
  max-height: 70px;
  opacity: 0.2;
}
</style>
