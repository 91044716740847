import axios from '@axios'
import store from '@/store'

export default {
  async postCategoriaData(token) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getCategorias`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  },
  async postCategoriasData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/tienda/getCategoriasLista`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idUsuario: id,
      },
    })
    return response
  },
  async postCategoriaDelete(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/tienda/deleteCategoriaLista`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idCategoria: id,
      },
    })
    return response
  },
  async postCategoriaUpdate(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/tienda/updateCategoriaLista`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        company: data.empresa,
        area: data.area,
        campaign: data.campana,
        name: data.nombre,
        description: data.descripcion,
        estado: data.estado,
        idValue: data.id,
        tipoUsuario: data.tipoUsuario,
        clase: data.clase,
      },
    })
    return response
  },
  async postCategoriaInsert(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/tienda/insertCategoriaLista`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    return response
  },
}
