import axios from '@axios'
import store from '@/store'

export default {
  async postClaseProductoData(token) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getClaseProducto`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  },
  async postProductoData(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getProductos`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idCategoria: id,
      },
    })
    return response
  },
  async postProductoTipoData(token) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getProductosTipo`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  },
  async postProductoDelete(token, id) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/deleteProductos`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idProducto: id,
      },
    })
    return response
  },
  async postProductoUpdate(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/updateProductos`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        idProducto: data.id,
        categoria: data.categoria,
        tipo: data.tipo,
        clase: data.clase,
        empresa: data.empresa,
        area: data.area,
        campana: data.campana,
        nombre: data.nombre,
        descripcion: data.descripcion,
        propiedad: data.propiedad,
        precio: data.precio,
        stock: data.stock,
        maximo: data.maximo,
        estado: data.estado,
        tipoUsuario: data.tipoUsuario,
        tituloCondiciones: data.tituloCondiciones,
        condiciones: data.condiciones,
        fechaIni: data.fechaIni,
        horaIni: data.horaIni,
        fechaFin: data.fechaFin,
        horaFin: data.horaFin,
        instruccionesCanje: data.instrucciones,
      },
    })
    return response
  },
  async postProductoInsert(token, data) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/insertProductos`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data,
    })
    return response
  },
  async getProductoModo(token) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getModoProducto`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  },
  async getProductoAutenticacion(token) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getListaAutenticacion`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  },
  async getProductoFormularioCantadas(token) {
    const response = await axios({
      method: 'post',
      url: `${store.state.app.api}/propiedades/getFormularioCantadas`,
      timeout: `${1000 * store.state.app.timeout}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    return response
  },
}
